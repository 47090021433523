<template>
    <div class="scroll-container">
      <div class="scroll-content">
      <div class="form-group">
        <div class="row">
          <div class="col-4">
            <label for="iStamp">Month</label>
            <input type="month" @input="genReport()" name="idate" id="idate" class="form-control" v-model="shroffObj.idate" />
          </div>
        </div>
      </div>
  
      <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    
      <div class="invoice-contain">
        <div class="invoice-form-contain">
          <form id="invoiceForm" novalidate autocomplete="off">
            <div id="PrintContent"><div class="hidden-content">This is hidden content.</div>
              <h5>LG 14</h5>
              <div class="form-group">
                <div class="heading">
                  <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">
                    Monthly Cross Note Expense Report | මාසික හරස් සටහන් වියදම් වාර් තාව<br>
                    {{ sabhadetail.sb_name_en }} | {{ sabhadetail.sb_name_sin }}<br>
                    {{ sabhadetail.sb_address }}
                  </h4>
                </div>
                <h6 style="padding-top: 0px; padding-bottom: 0px;">
                  <label for="iDate">
                    Year and Month | වර්ෂය හා මාසය: {{ this.shroffObj.idate }}
                  </label>
                </h6>
                <table style="width: 98%; text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">
                  <!-- <thead>
                    <tr>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Index</th>
                      <th colspan="2" style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Income Code <br>ලැබීම් කේතය</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;" :colspan="headlen">Program | වැඩසටහන </th>
                      <th  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total(Rs.) <br>එකතුව(රු.)</th>
                    </tr>
                  </thead> -->
                  <thead>
                    <tr>
                    <th colspan="2" rowspan="2"  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; " >වැය විස්තරය විෂය නාම හා වැය සංකේතය</th>
                    
                      <th colspan="3"  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; " >මාසයේ වියදම</th>
                      <th colspan="3"  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">පෙර මාසයේ අවසාන දිනට වියදම</th>
                      <th rowspan="2"  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;"> වියදම</th>
                      </tr>
                    <tr>
                      
                      <!-- <th  style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">ඇස්තමේන්තුව</th> -->
                       
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">මුදලින්</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">හරස් සටහන්</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">මුළු වියදම</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;"> මුදලින්</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">හරස් සටහන්</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">මුළු වියදම</th>
                    
                    </tr>
                  </thead>
                  <tbody>
        <template v-for="(item, index) in allHeads" :key="index">
            <template v-if="item.sbhead && item.sbhead.length > 0">
              <tr style="background-color:silver;">
          <td  style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ item.progheadId }}</b></td>
          <td  style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ item.progheadName }}</b></td>

          <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol1)}}&nbsp;</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol6)}}&nbsp;</b> </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol2)}}&nbsp; </b></td>

                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol7)}}&nbsp; </b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{$formatNumber(item.totalCol8)}}&nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol4)}}&nbsp;</b> </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol3)}}&nbsp;</b> </td>
        </tr>
        <tr v-for="(subHead, subIndex) in item.sbhead" :key="subIndex">
            <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; width: 12%; white-space: normal;"> {{ subHead.sb_rate_head }}</td>
          <td style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40; width: 25%; white-space: normal;">{{ subHead.sb_rate_head_name }}</td>
          
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber(subHead.hsummoney)}}&nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{$formatNumber(subHead.hSumcross) }}&nbsp; </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"> {{ $formatNumber(subHead.hSum)}}&nbsp;</td>

                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber( subHead.hsummoneyPreMonth)}}&nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ $formatNumber( subHead.hSumcrossPreMonth)}} &nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"> {{$formatNumber(subHead.collectedIncome)}}&nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40; width:5%;" >{{ $formatNumber(subHead.sumMonthRange )}}&nbsp;</td>
                     <!-- Add more columns as needed -->
        </tr>
        <!-- <tr>
            <td  style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;"><b>එකතුව</b></td>
            <td style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;">&nbsp;</td>
            <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol1)}}&nbsp;</b></td>
                  
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol2)}}&nbsp;</b> </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol4)}}&nbsp; </b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol3)}}&nbsp; </b></td>
                      <td style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;">&nbsp;</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{$formatNumber(item.totalCol5)}}&nbsp;</b> </td>
         </tr> -->
        <tr>
          <td colspan="13" style="text-align: left; border-width: 1px; border-style: solid; border-color: #130f40;">&nbsp;</td>
        </tr>
        </template>
      </template>
                  </tbody>
                </table>
                <br>
                
                <h6>Prepared By | සකස් කලේ ................................................................................... &nbsp; &nbsp;&nbsp;Checked By |පරික්ෂා කලේ .......................................................................... </h6>
                <h6>Date | දිනය&nbsp;&nbsp;................................................................................&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Date | දිනය &nbsp;&nbsp;................................................................................</h6>
              </div>
            </div>
          </form>
          <div class="form-group">
            <input type="button" value="Print" class="btn" @click="fetchDataAndPrint" />
            <input  type="button" id="excebtn" value="To Excel" class="btn"  @click="exportTableToExcel" :disabled="disableButton" />
            
            
          </div>
        </div>
        <br>
      </div>
      </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import VueBasicAlert from 'vue-basic-alert';
    import * as XLSX from 'xlsx';
    // import * as XLSXStyle from 'xlsx-style';
    export default {
      name: "LgSix",
      data() {
        return {
          nic: "",
          sabha: "",
          userLevel: "",
          userName: "",
          sabhadetail: [],
         
         
          date: new Date(),
        
          headsar: [],
          head_total: 0,
          shroffObj: { idate:"" },
          
          incomeMap: {},
          vat_total:0,
          incomeHsabha: [] ,// Array to store dates
          selectedMonth:"",
          selectedYear:"",
          discount:0,
          totalGenData: [],
          disableButton:true,
          headlen:0,
          totalResults: {},
          allProghead:[],
          allHeads: [],
          estIncome:[],
          income: undefined,
          hSum:[],
          hSummoney:[],
          hSumcross:[],
          hSummoneyPreMonth:[],
          hSumcrossPreMonth:[],
          
          sumMonthRange:[],
          collectedIncome:[],
          collectedInPer:[],
          Colunm10:[],
          Colunm11:[],
          Colunm12:[],
          Colunm13:[],
          totalCol1:[],
          totalCol2:[],
          totalCol3:[],
          totalCol4:[],
          totalCol5:[],
          totalCol6:[],
          totalCol7:[],
          totalCol8:[],
          totalCol9:[],
          totalCol10:[],


         
        };
     },
    
      created() {
        this.getDataFromSessionStorage();
      //  this.genReport();
      },
    
      methods: {
   
    formatDate(date) {
        const day = date.getDate().toString().padStart(2, "0");
        const monthcc = (date.getMonth()).toString();
        let month=""
        if(monthcc==0){
             month = 12
        }else{
             month = (date.getMonth()).toString().padStart(2, "0");
        }
          const year = date.getFullYear().toString();
          return `${year}-${month}-${day}`;
    },
  
    
        async getDataFromSessionStorage() {
          const data = JSON.parse(sessionStorage.getItem('userData'));
          if (data) {
            this.nic = data.nic;
            this.sabha = data.sabha;
            this.userLevel = data.userLevel;
            this.userName = data.userName;
          }
          this.sabhadetail = (await axios.get('/pra_sabha/' + this.sabha)).data;
     
        },

        async estData(vote) {
            try {
                const esticomedata = await axios.get(`/expestincome/${this.sabha}/${vote}/${this.selectedYear}`);
                this.estIncome = esticomedata.data;
                return this.estIncome[0]?.est_expense || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        async SumOfHead(vote) {
            try {
                const headsum = await axios.get(`/expsumofhead/${this.sabha}/${vote}/${this.selectedYear}/${this.selectedMonth}`);
               this.hSum = headsum.data;
                // return this.hSum[0]?.amount || 0;
                return this.hSum[0]?.amount || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        async sumBetweenMonth(vote) {
            try {
                const MonthRangeheadsum = await axios.get(`/expsumbetweenmonth/${this.sabha}/${vote}/${this.selectedYear}/${this.selectedMonth}`);
               this.sumMonthRange = MonthRangeheadsum.data;
                // return this.sumMonthRange[0]?.amount || 0;
                return this.sumMonthRange[0]?.amount || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        async SumOfHeadMoney(vote) {
            try {
                const headsummoney = await axios.get(`/expmoneysumofhead/${this.sabha}/${vote}/${this.selectedYear}/${this.selectedMonth}`);
               this.hSummoney = headsummoney.data;
                return this.hSummoney[0]?.e_amount || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        async SumOfHeadCross(vote) {
            try {
                const headsumcross = await axios.get(`/expcrosssumofhead/${this.sabha}/${vote}/${this.selectedYear}/${this.selectedMonth}`);
               this.hSumcross = headsumcross.data;
                return this.hSumcross[0]?.e_amount || 0;
                
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
       
        async SumOfHeadMoneyPremonth(vote) {
            let premonth=parseInt(this.selectedMonth)-1
            try {
                const headsummoneyPreMonth = await axios.get(`/exppremonthmoneysumofhead/${this.sabha}/${vote}/${this.selectedYear}/${premonth}`);
               this.hSummoneyPreMonth = headsummoneyPreMonth.data;
                return this.hSummoney[0]?.e_amount || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        async SumOfHeadCrossPremonth(vote) {
            let premonth=parseInt(this.selectedMonth)-1
            try {
                const headsumcrossPreMonth = await axios.get(`/exppremonthcrosssumofhead/${this.sabha}/${vote}/${this.selectedYear}/${premonth}`);
               this.hSumcrossPreMonth = headsumcrossPreMonth.data;
                return this.hSumcross[0]?.e_amount || 0;
                } catch (error) {
                console.error('Error fetching data:', error);
                throw error; // Ensure that any errors are propagated properly
                }
        },
        

        async genReport() {
      let sabhacode = this.sabha;
      const selectedmon =  (this.shroffObj.idate).split("-")
      this.selectedMonth=selectedmon[1]
      this.selectedYear=selectedmon[0]
      this.head_total=0
      this.allHeads=[]
      // this.getDatesInMonth(this.selectedMonth, this.selectedYear);
      // await this.fetchTotalGenData();
      
      try {
        
        const headsOfProg = await axios.get(`/expallproghead/`);
          this.allProghead = headsOfProg.data;
          let colTotal1=0;
          let colTotal2=0;
          let colTotal3=0;
          let colTotal4=0;
          let colTotal5=0;
          let colTotal6=0;
          let colTotal7=0;
          let colTotal8=0;
          // let colTotal9=0;
          // let colTotal10=0;
       

          for (let progh of this.allProghead) {
          let  progheadId= progh.income_head_id
          let  progheadName= progh.head_des_sin
          const headsbyprog = await axios.get(`/expsbheadsbyproghead/${sabhacode}/${progheadId}`);
          let sbhead = headsbyprog.data;
        //   this.allHeads.push(progheadId,progheadName,sbhead)
        this.allHeads.push({ progheadId, progheadName, sbhead });
          }
       
        for (let head of this.allHeads) {
        for (let subHead of head.sbhead) {
          subHead.hsummoney = await this.SumOfHeadMoney(subHead.sb_rate_head);
          colTotal1 +=parseFloat(subHead.hsummoney);

          subHead.hSumcross = await this.SumOfHeadCross(subHead.sb_rate_head);
          colTotal6 +=parseFloat(subHead.hSumcross)

          subHead.hsummoneyPreMonth = await this.SumOfHeadMoneyPremonth(subHead.sb_rate_head);
          colTotal7 +=parseFloat(subHead.hsummoneyPreMonth);

          subHead.hSumcrossPreMonth = await this.SumOfHeadCrossPremonth(subHead.sb_rate_head);
          colTotal8 +=parseFloat(subHead.hSumcrossPreMonth)


          subHead.hSum = await this.SumOfHead(subHead.sb_rate_head);
          colTotal2 +=parseFloat(subHead.hSum)

        //   subHead.sumMonthRange = await this.sumBetweenMonth(subHead.sb_rate_head); //expenses untill now
          subHead.sumMonthRange = await this.sumBetweenMonth(subHead.sb_rate_head); //expenses untill now
          colTotal3 +=parseFloat(subHead.sumMonthRange)

          let ColIncome=parseFloat(subHead.sumMonthRange)-parseFloat(subHead.hSum)
          subHead.collectedIncome=ColIncome.toFixed(2)
          colTotal4 +=parseFloat(subHead.collectedIncome)// this is colunm 3 value

          

          // let percenColIncome=parseFloat(subHead.collectedIncome)/parseFloat(subHead.estIncome)
          let percenColIncome = (parseFloat(subHead.collectedIncome) / parseFloat(subHead.estIncome)) * 100;
          subHead.collectedInPer=percenColIncome.toFixed(2)
          colTotal5 +=parseFloat(subHead.collectedInPer)

          // let columnVal10=parseFloat(subHead.estIncome)/12
          // subHead.Colunm10=columnVal10.toFixed(2)
          // colTotal6 +=parseFloat(subHead.Colunm10)

          // let columnVal11=parseFloat(subHead.hSum)/parseFloat(subHead.Colunm10)
          // subHead.Colunm11=columnVal11.toFixed(2)
          // colTotal7 +=parseFloat(subHead.Colunm11)

          // let columnVal12=(parseFloat(subHead.estIncome)/12)*parseInt(this.selectedMonth)
          // subHead.Colunm12=columnVal12.toFixed(2)
          // colTotal8 +=parseFloat(subHead.Colunm12)

          // let columnVal13=parseFloat(subHead.sumMonthRange)/parseFloat(subHead.Colunm12)
          // subHead.Colunm13=columnVal13.toFixed(2)
          // colTotal9 +=parseFloat(subHead.Colunm13)
        }
        head.totalCol1=colTotal1.toFixed(2)
        head.totalCol2=colTotal2.toFixed(2)
        head.totalCol3=colTotal3.toFixed(2)
        head.totalCol4=colTotal4.toFixed(2)
        head.totalCol5=colTotal5.toFixed(2)
        head.totalCol6=colTotal6.toFixed(2)
        head.totalCol7=colTotal7.toFixed(2)
        head.totalCol8=colTotal8.toFixed(2)
        // head.totalCol9=colTotal9.toFixed(2)
        colTotal1=0
        colTotal2=0
        colTotal3=0
        colTotal4=0
        colTotal5=0
        colTotal6=0
        colTotal7=0
        colTotal8=0
        // colTotal9=0
      }
      
              this.disableButton = false;
      } catch (error) {
          console.error('Error generating report:', error);
      }
      
  },
  getIncomeAmount(headName,prog) {
    
      try {
      const value = this.incomeMap[headName] && this.incomeMap[headName][prog] !== undefined
        ? this.incomeMap[headName][prog]
        : 0;
  
      return typeof value === 'number' && value !== 0 ? value.toFixed(2) : '';
    } catch (error) {
      console.error('Error in getIncomeAmount:', error);
      return '';
    }
    },
   
        calculateTotal(prog) {
          let total = 0;
          this.incomeHsabha.forEach(sum => {
              total += this.incomeMap[sum.sb_rate_head]?.[prog] || 0;
          });
          return total;
        },
        getSbRateHeadTotal(sb_rate_head) {
      const headAmounts = this.incomeMap[sb_rate_head] || {};
      let total = 0;
      for (let key in headAmounts) {
        // Use Object.prototype.hasOwnProperty.call to avoid the ESLint warning
        if (Object.prototype.hasOwnProperty.call(headAmounts, key)) {
          total += headAmounts[key];
        }
      }
      return total;
    },
    fetchDataAndPrint() {
  
  const prtHtml = document.getElementById('PrintContent').innerHTML;
  
  // Open the print window
  // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,status=0');
  const WinPrint = window.open('', '', '');
  WinPrint.document.write(`
  ${prtHtml}
  `);
  
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
  },
  exportTableToExcel() {
      // Get the HTML table element
      const table = document.getElementById('PrintContent');
      
      // Convert the table to a worksheet
      const ws = XLSX.utils.table_to_sheet(table);
  
      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // Generate an Excel file and trigger download
      XLSX.writeFile(wb, 'ExportedContent.xlsx');
    }
  },
      components: {
        VueBasicAlert
      }
    };
    </script>
   <style scoped>
   .hidden-content {
    display: none; /* Initially hidden */
  }
   .invoice-contain {
       background-color: #fff;
       /* height: 100vh; */
       /* padding: 2rem 25%; */
       padding: 1rem;
       font-size: 16px;
       align-content: center;
       min-height: 72.3vh;
   }
   .invoice-contain .invoice-form-contain {
       background: #fff;
   
   }
   
   .invoice-contain .invoice-form-contain form {
       position: relative;
   }
   .invoice-contain .invoice-form-contain form h3 {
       /* padding-bottom: 1rem; */
       font-size: 1.7rem;
       text-transform: uppercase;
       color: #130f40;
       margin: 0;
   }
   .ttable {
       
       width: 100%;
       border-width:1px;
       border-color : #130f40;
       /* border-style: solid; */
       font-size: 0.9rem;
       /* background-color :white; */
       /* padding-left: 3rem; */
        /* height: 3rem; */
        border-collapse: collapse;
   }
   th  {
       text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  
   
   .table-responsive {
       margin-top: 8vh;
       height: 500px;
   }
   
   .action-btn,
   .cancel-btn,
   .paid-btn {
       width: 100px;
       height: 25px;
       color: white;
       text-transform: capitalize;
   }
   
   .action-btn {
       background-color: #0da9ef;
       margin-right: 10px;
   }
   
   .cancel-btn,
   
   .action-btn:hover {
       background-color: #27ae60;
   }
   
    .invoice-contain .invoice-form-contain form .form-group {
       margin:0;
       font-size: 1rem;
   } 
   
   .invoice-contain .invoice-form-contain form .form-group .error-mess {
       font-size: 1.5rem;
       position: relative;
       color: rgb(243, 47, 47);
       margin: 0;
       padding: 0;
   }
   .invoice-contain .btn {
       background: rgb(223, 80, 14);;
       color: rgb(240, 234, 231);
       border: 1rem;
       padding: 1rem;
       font: inherit;
       cursor: pointer;
       outline: 1rem;
       text-align: center;
      
   }
   .invoice-contain .btnrow{
      align-content: center;
      padding-left: 25rem;
   }
  
   .scroll-container {
    width: 100%; /* Set the width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent content from wrapping */
  }
  /*
  //.scroll-content {
     Optional: Set width if necessary 
  //}*/
   </style>