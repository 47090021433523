
<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section>
    
    <div class="register-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Manage Property Owners</h1>
       </div>
        <div class="register-form-container">
            <form id="ShopDetailForm" novalidate autocomplete="off" >
                <div class="heading">
            <h3><b>Add Property Owners</b></h3>
            </div>
            <div class="row">
                <div class="form-group col-sm-3">
                    <label for="usedn"> <b>Property ID:</b>
                    </label>
                    <div>
                        <input 
                        v-model="obj.prop" 
                        id="wrd" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                        <p class="error-mess" v-if="errorObj.propErr.length > 0">{{ errorObj.propErr[0] }}</p>
                    </div>
                   
               </div>
               <div class="form-group col-sm-3">
                   <label for="yrval"><b>Ownership Starts at:</b></label>
                   <input type="date" name="yrval" id="yval" class="form-control" 
                   v-model="obj.startd" />
                    <!-- <p class="error-mess" v-if="errorObj.valErr.length > 0">{{ errorObj.valErr[0] }}</p> -->
               </div>
                
            </div>
            <!-- <div v-for="(owner, index) in owners" :key="index" class="row">
                <div class="form-group col-sm-3">
                <label for="val"><b>Owner's NIC:</b></label>
                <input type="text"  @input ="showCustomer(owner, index)"   v-model="owner.nic" class="form-control" />
                <p class="error-mess" v-if="errorObj.nicErr[index]?.length > 0">{{ errorObj.nicErr[index][0] }}</p>
            </div> -->
            <div v-for="(owner, index) in owners" :key="index" class="row">
            <div  class="form-group col-sm-8">
                <label for="usedn"><b>Owner's Name:</b></label>
                <input type="text"  v-model="owner.owname" class="form-control" />
                <p class="error-mess" v-if="errorObj.ownameErr[index]?.length > 0">{{ errorObj.ownameErr[index][0] }}</p>
            </div>

            <!-- <div class="form-group col-sm-3">
                <label for="states"><b>Address:</b></label>
                <input type="text" v-model="owner.addr" class="form-control" />
                <p class="error-mess" v-if="errorObj.addrErr[index]?.length > 0">{{ errorObj.addrErr[index][0] }}</p>
            </div>
            <div class="form-group col-sm-2">
                <label for="rate"><b>Contact Number:</b></label>
                <input type="text" v-model="owner.contact" class="form-control" />
                <p class="error-mess" v-if="errorObj.contactErr[index]?.length > 0">{{ errorObj.contactErr[index][0] }}</p>
            </div> -->
            <div class="form-group col-sm-1">
                <!-- <label for="btnplus" style="color: transparent;"> <b> Add </b></label> -->
            <input type="button" @click="addNew()" value="+" class="btn2" />
        </div>
        </div>

            <!-- <div class="row"> -->
                <!-- <div class="form-group col-sm-4">
                   <label for="powner"> <b>Ownership Ended date:</b></label>
                   <input type="date" name="yrval" id="yval" class="form-control" 
                   v-model="obj.endd" />
                   // <p class="error-mess" v-if="errorObj.ownerErr.length > 0">{{ errorObj.ownerErr[0] }}</p>
              </div> -->
              
            <!-- </div> -->
                <div class="form-group">
                    <input type="button" @click="saveOwner()" value="Save" class="btn" />
                    
                </div>
            </form>
        </div>
    </div>
</section>
<div class="Shop-container">
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
            
            <div class="heading">
            <h3><b>Current Ownership Details</b></h3>
            </div>
           <div class="row">
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                       <th>Index</th>
                       <th>Property ID</th>
                       <th>Owner</th>
                       <!-- <th>Owner NIC</th>
                       <th>Contact</th>
                       <th>Address</th> -->
                       <th>ownership Started date</th>
                       <!-- <th>Edit Owner Details</th> -->
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">

                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td >{{ s.property_id  }}</td>
                       <td >{{ s.cus_name  }}</td>
                       <!-- <td>{{ s.cus_nic  }}</td>
                       <td>{{ s.cus_contact  }}</td>
                       <td>{{ s.cus_address  }}</td> -->
                       <td>{{  new Date(s.start_date).toLocaleDateString()  }}</td>
                       <!-- <td><router-link to="" @click="openPopup(s.o_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td> -->
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
       </div>
</div>
<!-- popup edit -->
     <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h1>Edit Current Property Owner's Details</h1>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="model-group">
                    <p><b>Use this form to edit current owners details. For changing property owner Please Use Change ownerhip form </b></p>
                   <label for="steetname"><b>Property ID:</b>
                   </label>
                   <input type="text" name="strrtn" class="form-control" 
                   v-model="popObj.propid"/>
                   <!-- <p class="error-mess" v-if="popErr.popstreetErr.length > 0">{{ popErr.popstreetErr[0] }}</p> -->
               </div>
            </div>
        </div> 
        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="UpdateStreet">Update</button>
        </div>
      </div>
     </div>
              <!-- popup end -->
    
</template>
<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
// import { ref } from 'vue';
export default {
   
    name: "AssessOwnership",
  
    data() {
        return {
            obj:{prop:"",owname:"",nic:"",contact:"",addr:"",startd:"",endd:""},
            errorObj: {propErr: [], ownameErr:[], nicErr:[],contactErr:[],addrErr:[]},
            owners: [{ owname: '', nic: '', contact: '', addr: '' }],
            sabaProp:[],
            itemsPerPage: 10,
            currentPage: 1,
            ownerDetails:[],
            showPopup:false,
            selectedowner:[],
            popObj:{propid:"",},
            paddedNum:"",
            len:0,
            checkforproperty:[],
            isLoading:false,
        }
    },

        created() {
            this.getDataFromSessionStorage()
        },
  
        computed : {
            paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.ownerDetails)) {
        return this.ownerDetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.ownerDetails.length / this.itemsPerPage);
    },

},     
    methods: {
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }

           this.sabaProp = (await axios.get('/props/' +this.sabha)).data
           this.ownerDetails = (await axios.get('/propown/' +this.sabha)).data
       },
       formattedDate() {
           let date = new Date()
           const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
        const fdate=`${year}-${month}-${day}`;
        const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
            return fdate.toString('en-US', options);
         },
         nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        // addZero(num) {

        // num = num.toString();
        //     return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
        // },
        // addZeroPadding: function() {
        // this.len= this.owners.nic.charAt(9).toUpperCase()
        // // Check if the user input is a valid number
        // if (!isNaN(parseInt(this.owners.nic)) && this.owners.nic !== null && (this.owners.nic.charAt(9).toUpperCase() === 'V' || this.owners.nic.charAt(9).toUpperCase() === 'X')) {
            
        //     this.paddedNum = this.addZero(this.owners.nic);
        
        // }else{
        //     this.paddedNum=parseInt(this.owners.nic);
        // }
        // return this.paddedNum
        // },
    //     addZeroPadding(ownerNic) {
    //     if (ownerNic && ownerNic.length > 9) {
    //         this.len = ownerNic.charAt(9).toUpperCase();
    //         // Check if the user input is a valid number and if the 10th character is 'V' or 'X'
    //         if (!isNaN(parseInt(ownerNic)) && ownerNic !== null && (ownerNic.charAt(9).toUpperCase() === 'V' || ownerNic.charAt(9).toUpperCase() === 'X')) {
            
    //         this.paddedNum = this.addZero(ownerNic);
        
    //     }else{
    //         this.paddedNum=parseInt(ownerNic);
    //     }
    //     return this.paddedNum
    // }
    //     },
//         async showCustomer(owner, index) {
//     try {
//         // const paddedNic = this.addZeroPadding(owner.nic); // Pass the owner's NIC
//         const response = await axios.get(`/customers/${paddedNic}/${this.sabha}`);
//         const theUser = response.data;

//         if (theUser.length > 0) {
//             // Update the specific owner's details reactively
//             this.owners[index] = {
//                 ...owner,
//                 owname: theUser[0].cus_name,
//                 addr: theUser[0].cus_address,
//                 contact: theUser[0].cus_contact
//             };
//         } else {
//             // Clear the specific owner's details if no user is found
//             this.owners[index] = {
//                 ...owner,
//                 owname: '',
//                 addr: '',
//                 contact: ''
//             };
//         }
//     } catch (error) {
//         console.error('Error fetching customer data:', error);
//     }
// },
        async checkProperty(prop){

            this.checkforproperty = (await axios.get('/chkprop/'+prop+"/"+this.sabha )).data
        },
       resetCheckErr: function () {
        // this.errorObj.propErr =[];
        this.errorObj.ownameErr=[];
        // this.errorObj.nicErr = [];
        // this.errorObj.contactErr = [];
        // this.errorObj.addrErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errorObj) {
               if (this.errorObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.obj.prop) {
               this.errorObj.propErr.push("required");
           }
           // Check each owner's details
        this.owners.forEach((owner, index) => {
            if (!owner.owname) {
                this.errorObj.ownameErr[index] = this.errorObj.ownameErr[index] || [];
                this.errorObj.ownameErr[index].push("required");
            }
            // if (!owner.nic) {
            //     this.errorObj.nicErr[index] = this.errorObj.nicErr[index] || [];
            //     this.errorObj.nicErr[index].push("required");
            // }
            // if (!owner.contact) {
            //     this.errorObj.contactErr[index] = this.errorObj.contactErr[index] || [];
            //     this.errorObj.contactErr[index].push("required");
            // }
            // if (!owner.addr) {
            //     this.errorObj.addrErr[index] = this.errorObj.addrErr[index] || [];
            //     this.errorObj.addrErr[index].push("required");
            // }
        });
    },
       //dynamic save
       addNew(e) {
        this.checkForm();
        
        // If there are errors, prevent adding a new owner
        if (!this.checkEmptyErr()) {
            e.preventDefault();
        } else {
            // this.owners.push({ owname: '', nic: '', contact: '', addr: '' });
            this.owners.push({ owname: '' });
            // Reset error messages for the new owner
            this.errorObj.ownameErr.push([]);
            // this.errorObj.nicErr.push([]);
            // this.errorObj.contactErr.push([]);
            // this.errorObj.addrErr.push([]);
        }
},
       //////////////////    save to `asses_property` table    ///////////////
    async saveOwner(e) {
    this.checkForm();

    if (!this.checkEmptyErr()) {
        e.preventDefault();
        return; // Exit early if there are errors
    }

    try {
        // Loop over owners and send individual requests
        for (const owner of this.owners) {
            const ownerData = {
                property_id: this.obj.prop,
                owner_nic: '',
                owner_name:owner.owname,
                start_date: this.obj.startd,
                sb_code: this.sabha,
            };

            await axios.post("/saveowner/", ownerData);
            // Step 2: Loop over owners and handle each owner individually
            // for (const owner of this.owners) {
                // const paddedNic = this.addZeroPadding(owner.nic);

            // Check if the customer already exists
        //     const response = await axios.get(`/customers/${paddedNic}/${this.sabha}`);
        //     const theUser = response.data;

        //     if (theUser.length === 0) {
        //     const customerData = {
        //         sabha_code: this.sabha,
        //         cus_nic: this.addZeroPadding(owner.nic),
        //         cus_name: owner.owname,
        //         cus_contact: owner.contact,
        //         cus_address: owner.addr,
        //     };

        //     await axios.post("/customers/", customerData);
        // }
        // }
    }
        // Fetch updated owner details
        this.ownerDetails = (await axios.get('/propown/' + this.sabha)).data;
        this.resetForm()
        this.$refs.alert.showAlert('success', 'Added Successfully!');
    } catch (error) {
        console.error('Error saving owner:', error);
        this.$refs.alert.showAlert('error', 'There was an error saving the owner. Please try again!');
    }
},

       resetForm() {
        this.obj.prop = '';
        this.obj.startd = '';
        this.owners = [{ owname: '', nic: '', contact: '', addr: '' }];
        this.resetCheckErr(); // Clear errors
    }
    //    -----------------------------------------------popup--------------------
// async openPopup(id) {
//             this.showPopup = true;
//             document.addEventListener('mouseup', this.closeModalOnClickOutside);
//             this.selectedowner= (await axios.get('/ownerde/'+id)).data;
//             if( this.selectedowner){
//             this.popObj.propid=this.selectedowner[0].property_id;
//             // this.selected=id;
//             }
//         },
//         closePopup() {
//             this.showPopup = false;
//             document.removeEventListener('mouseup', this.closeModalOnClickOutside);
//         },
//         // closeModalOnClickOutside(event) {
//         //     const modal = this.$refs.modalRef;
//         //     if (!modal.contains(event.target)) {
//         //         this.closePopup();
//         //     }
//         // },
//         // popup empty check
//         checkPopEmptyErr: function () {
//             for (var typeErr in this.popErr) {
//                 if (this.popErr[typeErr].length != 0) {
//                     return false;
//                 }
//             }
//             return true;
//         },
//         resetCheckArrPopErr: function () { 
//             this.popErr.popstreetErr = [];
          
//         },
//         checkArePopup: function () {
//             this.resetCheckArrPopErr();
//             if (!this.popObj.Streetname) {
//                 this.popErr.popstreetErr.push("Name is required");
//             }
//         },
//         async UpdateStreet(s) {
//             this.checkArePopup();

//             if (!this.checkPopEmptyErr()) {
//                 s.preventDefault();
//             }else{

//                 let data ={
//                     sub_cat_name:this.popObj.Streetname
//                 }
//                 await axios.put("/upsubcatname/"+this.selectedcatid,data)
//                 this.$refs.alert.showAlert('success', 'Sub Category updated Successfully !')
//                 this.closePopup();
//                 this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
//             }
//         },
      
    },
    components: {
        VueBasicAlert
    }
}

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
.register-container {
    padding: 1rem 2%;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    
}
.register-container .register-form-container form .btn2 {
    margin: 2rem 0;
    width: 100%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    /* height: 3rem; */
    color: #e9e4e9;
    padding: 0.5rem;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 90%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
/* for second container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   font-size: 16px;
}

.Shop-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}
/* newly added from income heads */
.Shop-container {
    padding: 1rem 2%;
}
/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #af74a7;
    height: 3rem;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(22, 21, 21);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* pop up */
.modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      border-radius: 0.5rem;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 60%;
      position: relative;
      font-size: 1.3rem;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
      text-align: center;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 

  }
  .model-group {
      padding: 10px ;
      width:90%;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      /* margin-right: 10px; */
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}
</style>
